export const ActionTypes = {
    "USER_LOGIN": "USER_LOGIN",
    "LOGOUT": "LOGOUT",
    "LOGIN_START": "LOGIN_START",
    "LOGIN_END": "LOGIN_END",
    "LOGIN_SUCCESS": "LOGIN_SUCCESS",
    "LOGIN_ERROR": "LOGIN_ERROR",
    "LOGIN_FORGOT": "LOGIN_FORGOT",
    "LOGIN_VERIFY": "LOGIN_VERIFY",
    "SET_AUTH_DATA": "SET_AUTH_DATA",
    "START_LOADING": "START_LOADING",
    "END_LOADING": "END_LOADING",
    "USER_UNAUTHETICATED": "USER_UNAUTHETICATED",
    "SET_ALL_CUSTOMERS": "SET_ALL_CUSTOMERS",
    "SET_SELECTED_CUSTOMER": "SET_SELECTED_CUSTOMER",
    "CLEAR_SELECTED_CUSTOMER": "CLEAR_SELECTED_CUSTOMER",
    "ADD_COMPANY": "ADD_COMPANY",
    "UPDATE_CUSTOMER": "UPDATE_CUSTOMER",
    "DELETE_CUSTOMER": "DELETE_CUSTOMER",
    "SET_PAGINATION_LIMIT": "SET_PAGINATION_LIMIT",
    "SET_PAGINATION_OFFSET": "SET_PAGINATION_OFFSET",
    "REST_PAGINATION_OFFSET": "REST_PAGINATION_OFFSET",
    "SET_ALL_CONTRACTS":"SET_ALL_CONTRACTS",
    "SET_SELECTED_CONTRACT":"SET_SELECTED_CONTRACT",
    "REMOVE_SELECTED_CONTRACT":"REMOVE_SELECTED_CONTRACT"
}

export const REGEX = {
    "EMAIL":/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "PHONE_NUM":/^([0-9\(\)\/\+ \-]*)$/,
    "NAME":/[a-zA-Z0-9][^\s]$/
}