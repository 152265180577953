import { toast } from "react-toastify"

export default function mergeConditionalClasses(...classes: any) {
    return classes.filter(Boolean).join(' ')
} // this function will join all classes and return string of classes

export function handleErorrMessage(err:any) {
    if(Array.isArray(err.response.data.message)){
        err.response.data.message.forEach((el:any) => {
            toast.error(el)
        })
    }
    else{

        toast.error(err.response.data.message)
    }
}

export function getYears(from:number, to:number) {
    let tempArr = [];
    for(let i = from; i <= to; i++){
        tempArr.push({
            title: i.toString(),
            value: i
        })
    }
}