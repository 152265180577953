import { ActionTypes } from "utils/constants"

let initialState = {
    pagination: {
        limit: 8,
        offset: 0,
        searchString: ""
    },
    data: []
}

export const CustomersReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.SET_ALL_CUSTOMERS:
            return { ...action.payload }
    }
    return state
}

export const selectedCustomerReducer = (state = {}, action: any) => {
    switch (action.type){
        case ActionTypes.SET_SELECTED_CUSTOMER:
            return {
                ...state,
                ...action.payload
            }
        case ActionTypes.CLEAR_SELECTED_CUSTOMER:
            return {}
    }
    return state
}
