import React, { useEffect } from 'react';
import Routes from 'routes/Routes';
import "common/assets/styles/global.scss"
import "libs/i18n/config.ts"
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorageData } from 'store/actions/auth.actions';
import Loader from 'common/components/Loader';
import 'rc-tooltip/assets/bootstrap_white.css';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLocalStorageData())
  }, [])

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Loader />
      <div className='w-full h-full min-w-screen min-h-screen bg-theme-bg font-mulish'>
          <Routes />
      </div>
    </>
  );
}

export default App;
