import { ActionTypes } from "utils/constants";

let initialState = {
    contracts: {},
    selectedContract: null
}

export const contractsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.SET_ALL_CONTRACTS:
            return {
                ...state,
                contracts: action.payload
            }
        case ActionTypes.SET_SELECTED_CONTRACT:
            return {
                ...state,
                selectedContract: action.payload
            }
        case ActionTypes.REMOVE_SELECTED_CONTRACT:
            return {
                ...state,
                selectedContract: null
            }
    }
    return state
}