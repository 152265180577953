import FallBack from "common/components/FallBack";
import React, { Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
const PrivateRoute = React.lazy(() => import('./PrivateRoute'));
const RoleBaseRoute = React.lazy(() => import('./RoleBaseRoute'));
const Login = React.lazy(() => import('common/views/Login'));
const Signup = React.lazy(() => import('common/views/Signup'));
const ChangePassword =  React.lazy(() => import("common/views/ChangePassword"));
const Forgot =  React.lazy(() => import("common/views/Forgot"));
const Verify =  React.lazy(() => import("common/views/Verify"));

const Routing = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [location.pathname])

    return (
        <>
            <Suspense fallback={<FallBack />}>
                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="forgot" element={<Forgot />} />
                    <Route path="verify/:email" element={<Verify />} />
                    <Route path="changepassword" element={<ChangePassword />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="/*" element={<PrivateRoute><RoleBaseRoute /></PrivateRoute>} />
                </Routes>
            </Suspense>
        </>
    )
}

export default Routing;
