import { combineReducers } from "redux";
import authReducer from './auth.reducer';
import { CustomersReducer, selectedCustomerReducer } from "./customer.reducer";
import { contractsReducer } from "./driver.reducer";
import loadingReducer from "./loader.reducer";

const rootReducer = combineReducers({
    authData: authReducer,
    customers: CustomersReducer,
    selected_customer: selectedCustomerReducer,
    loading: loadingReducer,
    contracts: contractsReducer,
})

export default rootReducer;
