import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json')
    },
    da: {
      translations: require('./locales/da/translations.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations',
  detection:{
    // order and from where user language should be detected
    order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  }
});

i18n.languages = ['en', 'da'];

export default i18n;