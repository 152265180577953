import { ActionTypes } from "utils/constants";

let initialState = {
    authenticated: !!localStorage.getItem("authData"),
}

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.USER_LOGIN:
            return {
                authenticated: true,
                ...action.payload
            }
        case ActionTypes.USER_UNAUTHETICATED:
            return {
                authenticated: false,
            }
        case ActionTypes.LOGOUT:
            return {
                authenticated: false,
            }
    }
    return state;
}

export default authReducer;