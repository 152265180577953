import axios from "axios";
import { toast } from "react-toastify";
import { AppDispatch } from "store";
import { axiosClient } from "utils/axios.interceptor";
import { ActionTypes } from "utils/constants"


export const getAuthData: any = (body: any, navigate: any) => (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.LOGIN_START });
    axiosClient.post("auth/login", body)
        .then(res => {
            if (res.status === 201) {
                localStorage.setItem("authData", JSON.stringify(res.data));
                dispatch({ type: ActionTypes.USER_LOGIN, payload: res.data });
                navigate("/");
            }
        })
}

export const sendCode: any = (body: any, navigate: any) => (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.LOGIN_FORGOT });
    axiosClient.post("auth/forgot", body)
        .then(res => {
            if (res.status === 201) {
                navigate(`/verify/${body.email}`);
            }
        })
}

export const verifyCode: any = (body: any, navigate: any) => (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.LOGIN_VERIFY });
    axiosClient.post("auth/verify", body)
        .then(res => {
            if (res.status === 201) {
                navigate(`/changepassword`,{
                    state: res.data
                });
            }
        })
}

export const changePassword: any = (body: any, navigate: any,additionalData:any) => (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.LOGIN_VERIFY });
    axiosClient.patch(`users/${additionalData.uuid}`, body,{
        headers:{
            Authorization: `Bearer ${additionalData.token}`
        }
    })
        .then(res => {
            if (res.status === 200) {
                navigate(`/login`);
            }
        })
}

export const logout: any = () => (dispatch: AppDispatch) => {
    localStorage.setItem("authData", JSON.stringify({}));
    dispatch({ type: ActionTypes.LOGOUT })
}

export const setAuthData = (data: any) => {
    return {
        type: ActionTypes.USER_LOGIN,
        payload: data
    }
}

export const getLocalStorageData: any = () => async (dispatch: AppDispatch) => {
    let localStorageData = localStorage.getItem("authData");

    if (localStorageData != null && localStorageData != JSON.stringify({})) {
        dispatch({ type: ActionTypes.USER_LOGIN, payload: JSON.parse(localStorageData) })
    }
    else {
        localStorage.setItem("authData", JSON.stringify({}))
        dispatch({ type: ActionTypes.USER_UNAUTHETICATED })
    }
}