import { endLoader, startLoader } from "store/actions/loading.actions";
import axios from "axios"
import store from "store"
import { handleErorrMessage } from "./helper";

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

const getToken = () => localStorage.getItem("authData") ? JSON.parse(localStorage.getItem("authData") as string).accessToken : null;

axiosClient.interceptors.request.use(
    (config: any) => {
        store.dispatch(startLoader())
        if(getToken()){
            config.headers.Authorization = `Bearer ${getToken()}`
        }
        return config;
    },
    (error: any) => {
        store.dispatch(endLoader())
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (config: any) => {
        store.dispatch(endLoader())
        return config;
    },
    (error: any) => {
        console.log(error);
        store.dispatch(endLoader())
        handleErorrMessage(error)
        if(error.response.status == 401){
            localStorage.removeItem("authData");
            window.location.replace("/login");
        }
        return Promise.reject(error);
    }
);