import { ActionTypes } from "utils/constants";

let initialState = {
    loading: false,
}

const loadingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionTypes.START_LOADING:
            return { loading: true }
        case ActionTypes.END_LOADING:
            return { loading: false }
    }
    return state;
}

export default loadingReducer;